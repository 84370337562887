<template>
    <UForm :schema="schema" :state="state" @submit="onSubmit" class="grid gap-y-5">
        <div class="grid gap-4 md:grid-cols-3 md:gap-10">
            <UFormGroup label="Name *" name="name">
                <UInput v-model="state.name" :disabled="pendingRequest" />
            </UFormGroup>
            <UFormGroup label="Phone number *" name="phone">
                <UInput v-model="state.phone" :disabled="pendingRequest" />
            </UFormGroup>
            <UFormGroup label="Email *" name="email">
                <UInput v-model="state.email" :disabled="pendingRequest" />
            </UFormGroup>
        </div>

        <div>
            <UFormGroup label="How can we help? (optional)" name="message">
                <UTextarea v-model="state.message" :disabled="pendingRequest" />
            </UFormGroup>

            <div class="flex flex-col md:flex-row md:items-center mt-5 md:mt-3 mb-7.5 md:mb-1 gap-5 md:gap-14">
                <UCheckbox name="agreePolicy" v-model="state.agreePolicy" :disabled="pendingRequest">
                    <template #label>
                        <span class="text-base font-bold">I agree to OCAUTOAID's <ULink to="/privacy-policy" class="text-blue-700">Privacy Policy.</ULink></span>
                    </template>
                </UCheckbox>

                <div class="flex items-center flex-1 gap-1.5 bg-slate-100 rounded-0.5xl p-3 text-slate-600 relative">
                    <UIIcon name="front/polygon" class="absolute h-5 w-5 -left-4 text-slate-100 justify-end hidden md:block"/>

                    <UIIcon name="front/shield-check"/>

                    <div>Your data will not be shared with third parties and will be used solely
                        for processing your request</div>
                </div>
            </div>

            <div class="flex items-start gap-10">
                <UButton type="submit" size="xl" variant="solid" class="w-full justify-center md:w-auto" :loading="pendingRequest">Submit</UButton>

                <UAlert
                    color="green"
                    variant="solid"
                    title="Thank you for reaching out!"
                    description="We’ve successfully received your message and will get back to you shortly. Our usual response time is within [insert time frame, e.g., 24 hours]."
                    v-if="showSuccessMessage"
                />
            </div>
        </div>
    </UForm>
</template>
<script setup lang="ts">
import {z} from 'zod';
import type { FormSubmitEvent } from '#ui/types';
const pendingRequest = ref(false);
const showSuccessMessage = ref(false);

const schema = z.object({
    name: z.string({
        required_error: 'This field should not be blank'
    }),
    phone: z.string({
        required_error: 'This field should not be blank'
    }),
    email: z.string({
        required_error: 'This field should not be blank'
    }).email(),
    message: z.string().optional(),
    agreePolicy: z.boolean()
});

type Schema = z.output<typeof schema>;

const state = reactive({
    name: 'dwadwa',
    phone: 'dwadwa',
    email: 'email@test.com',
    message: 'dwadwadwa',
    agreePolicy: false
});

const onSubmit = async (event: FormSubmitEvent<Schema>) => {
    await useFetch('/api/contact-form', {
        method: 'post',
        body: {
            ...state
        },
        onRequest() {
            pendingRequest.value = true;
        },
        onResponse() {
            pendingRequest.value = false;
            showSuccessMessage.value = true;

            Object.assign(state, {
                name: null,
                phone: null,
                email: null,
                message: null,
                agreePolicy: false
            });
        }
    });
}
</script>
